import api from "@/axios.js";
import Vue from "vue";

const state = {
  filter: {
    territory: [],
    territory_single: [],
    operatingUnit: [],
    selectedTerritories: [
      { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
    ],
    selectedTerritory: {},
    selectedOperatingUnits: [{ ID: 0, Code: "All", Name: "All" }],
  },
  whiteListUrl: [
    "cash-bank-management",
    "payment",
    "billing",
    "credit-note",
    "approval-cash-deposit",
    "approval-cash-deposit-reversal",
    "pod processing",
    "invoice exchange",
    "customer-deposit",
    "bank-statement",
    "invoice exchange edit",
    "payment",
    "credit-limit-request",
    "ar_payment_payment_received",
    "Payment",
    "bank-statement",
    "dn-with-or-without-po-ref",
    "create-credit-note-manual",
    "edit-credit-note-manual",
    "invoice-exchange",
    "ar-payment",
    "shipment-planning",
    "invoice.print",
    "transfer-customer-deposit",
    "approval generate invoice"
  ],
};

const mutations = {
  setTerritoryFilter(state, payload) {
    state.filter.territory = JSON.parse(JSON.stringify(payload));
  },
  setOperatingUnitFilter(state, payload) {
    state.filter.operatingUnit = JSON.parse(JSON.stringify(payload));
  },
  setTerritoryFilterSingle(state, payload) {
    state.filter.territory_single = JSON.parse(JSON.stringify(payload));
  },
  setTerritoryFilterByOperatingUnits(state) {
    let territory = JSON.parse(JSON.stringify(state.filter.operatingUnit));
    // territory.shift(); //remove "all" object from operating unit
    const singleTerritory = territory;
    const territory_id = Vue.prototype.$store.state.user.currentUser
      ? Vue.prototype.$store.state.user.currentUser.territory_id
      : 0;
    // { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
    territory = [...territory];
    if (territory_id === 0) {
      territory.unshift({
        ID: 0,
        TerritoryCode: "All",
        TerritoryName: "All",
      });
    }

    if (state.filter.selectedOperatingUnits.some((ou) => ou.ID === 0)) {
      state.filter.territory = territory;
      state.filter.territory_single = singleTerritory;
    } else {
      const filtered = territory.filter((ou) =>
        state.filter.selectedOperatingUnits.some((sou) => sou.ID === ou.ID)
      );
      state.filter.territory = filtered;
      state.filter.territory_single = filtered;
    }
    console.log("territory", state.filter.territory);
    console.log("operating unit", state.filter.operatingUnit);
  },
  setSelectedTerritories(state, payload) {
    state.filter.selectedTerritories = JSON.parse(JSON.stringify(payload));
  },
  setSelectedTerritory(state, payload) {
    state.filter.selectedTerritory = payload;
  },
  setSelectedOperatingUnits(state, payload) {
    state.filter.selectedOperatingUnits = JSON.parse(JSON.stringify(payload));
  },
  resetState(state) {
    // const territory_id = Vue.prototype.$store.state.user.currentUser
    // ? Vue.prototype.$store.state.user.currentUser.territory_id
    // : 0;

    // if(territory_id === 0 && state.filter.operatingUnit.length >= 2){
    //   state.filter.selectedOperatingUnits = [{ ID: 0, Code: "All", Name: "All" }];
    //   state.filter.selectedTerritories = [{ ID: 0, TerritoryCode: "All", TerritoryName: "All" }];
    // }else{
    //   state.filter.selectedOperatingUnits = state.filter.operatingUnit[0];
    //   state.filter.selectedTerritories = state.filter.territory[0];
    // }
    state.filter.selectedOperatingUnits = [{ ID: 0, Code: "All", Name: "All" }];
    state.filter.selectedTerritories = [
      { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
    ];
    state.filter.selectedTerritory = {};
  },
  resetLogOut(state) {
    state.filter.selectedOperatingUnits = [{ ID: 0, Code: "All", Name: "All" }];
    state.filter.selectedTerritories = [
      { ID: 0, TerritoryCode: "All", TerritoryName: "All" },
    ];
    state.filter.selectedTerritory = {};
    state.filter.territory = [];
    state.filter.operatingUnit = [];
    state.filter.territory_single = [];
  }
};

const actions = {
  async getOperatingUnitWithTerritory({ commit }, params) {
    try {
      // Vue.$vs.loading();
      showLoading();
      const response = await api.get(
        "/api/v1/master/operating-unit-with-territory/personal",
        {
          params,
        }
      );
      // console.log(
      //   "current",
      //   Vue.prototype.$store.state.user.currentUser
      // );

      const operatingUnit = response.data.records;
      const territory_id = Vue.prototype.$store.state.user.currentUser
        ? Vue.prototype.$store.state.user.currentUser.territory_id
        : 0;
      const ou = [...operatingUnit];
      const territory = [...operatingUnit];

      if (territory_id === 0 || operatingUnit.length > 1) {
        ou.unshift({ ID: 0, Code: "All", Name: "All" });
        territory.unshift({
          ID: 0,
          TerritoryCode: "All",
          TerritoryName: "All",
        });
      }

      console.log("ou", ou);
      console.log("territory", territory);

      commit("setOperatingUnitFilter", ou);
      commit("setTerritoryFilter", territory);
      commit("setTerritoryFilterSingle", operatingUnit);
      if (territory_id > 0 && operatingUnit.length < 2) {
        commit("setSelectedOperatingUnits", [ou[0]]);
        commit("setSelectedTerritories", [territory[0]]);
      }

      hideLoading();
    } catch (error) {
      hideLoading();
      console.error(error);
      throw error;
    }
  },
};

const getters = {
  getSelectedTerritoryId(state) {
    if (
      state.filter.selectedTerritories.some((st) => st.TerritoryName === "All")
    ) {
      const territories = JSON.parse(JSON.stringify(state.filter.territory));
      if (territories[0].TerritoryName === "All") {
        territories.shift();
      }
      return territories.map((t) => t.TerritoryID);
    } else {
      return state.filter.selectedTerritories.map((t) => t.TerritoryID);
    }
  },
  getSelectedTerritoryIdSingle(state) {
    return state.filter.selectedTerritory.TerritoryID;
  },
};

function showLoading() {
  Vue.prototype.$vs.loading();
}

function hideLoading() {
  Vue.prototype.$vs.loading.close();
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
